//fomat
String.prototype.format = function(O){
	var s = this.replace(/\@\{(\w+)\}/g, function(t, _o){
		return O[_o];
	});
	return s;
}

//获取url参数
function getQueryString(name) {
	var reg = new RegExp("(^|&)"+ name +"=([^&]*)(&|$)");
	var r = window.location.search.substr(1).match(reg);
	if(r){
		return  decodeURI(r[2]);
	} else {
		return ""; 
	}
}

/*去除空格*/
var yzmTrim = function(str){
	return str.replace(/(^[\s\n\t]+|[\s\n\t]+$)/g, "");
};
/**
 * text：文本输入限制
 * @param inputInfo
 * @returns
 */
function judgeInput(inputInfo) {
	var pattern = /^[a-zA-Z0-9\u4E00-\u9FA5\\\/,:.;"!?@~()%=\+\-\n，。！＠～（）《》％＝＋－；、：“”？\s]*$/;
	if (pattern.test(inputInfo)){
		return true;
	}
	
	return false;
}

/**
 * elementId:当前元素id
 * 
 * loginCallback:session过期,弹出框自定义回调方法
 * loginMsg:session过期,弹出框自定义显示名称
 * 
 * callback000055:病例过期,弹出框自定义回调方法
 * callback000055Msg:病例过期,弹出框自定义显示名称
 * 
 * elseCallback:其他错误回调方法
 * 
 * errorCallback:出错回调方法
 * errorMsg:出错,弹出框自定义显示名称
 * 
 */
var dc_ajax = function(options){
	var ajaxDisableClassName = "ajax-disabled-class";
	
	$.ajax({ 
		url : options.url + '?time=' + Math.random(), 
		type : options.type, 
		data : options.data, 
		async : options.async || true,
		dataType : options.dataType,
		beforeSend : function () {
			if(options.elementId){
				// 禁用按钮防止重复提交
				if($("#" + options.elementId).hasClass(ajaxDisableClassName)){
					return false; 
				}           
				
				$("#" + options.elementId).addClass(ajaxDisableClassName);
			}
		},
		success : function(ret) {
			if(ret.code == '1'){
				options.success(ret);
			} else if (ret.code == '000023'){
				var loginCallback = function(){
					var href = '/logout';
					window.location.href = href;
				};
				
				yzmAlert({msg:(options.loginMsg || "您的账号已经过期，请重新登录"), okCallback:(options.loginCallback || loginCallback)});
			} else if (ret.code == '0' && ret.data == '000055'){
				var callback000055 = function(){
					window.location.href = "/security/doctor-case/list";
				};
				
				yzmAlert({msg:(options.callback000055Msg || ret.message), okCallback:(options.callback000055 || callback000055)});
			} else {
				if(options.customErrorHandle){
					options.customErrorHandle(ret);
				} else {
					yzmAlert({msg:(ret.message || '出错了！'), okCallback:options.elseCallback});
				}
			}
		}, 
		complete: function () {
			if(options.elementId){
				$("#"+options.elementId).removeClass(ajaxDisableClassName);
			}
		},
		error : function(jqXHR, textStatus, errorThrown) { 
			console.log(jqXHR);
			console.log(jqXHR.readyState);
		    console.log(jqXHR.status);
		} 
	});
};

/**
 * 调用方法决定是否隐藏新建病例/视频教程/病例中心
 */
var conceal_newCases=function(flag){
	if(flag == true){
		$(".header-btn").hide();
	}
};

var conceal_video = function(flag){
	if(flag == true){
		$("#video-course").hide();
	}
};

var conceal_case = function(flag){
	if(flag == true){
		$("#case-centre").hide();
	}
};
/**
 * 调用方法决定是否选中病例教程/视频教程
 */
var checked_case = function(flag){
	if(flag == true){
		$("#case-centre").addClass("pitch-on");
	}
};

var checked_vido = function(flag){
	if(flag == true){
		$("#video-course").addClass("pitch-on");
	}
};

var checked_dental = function(flag){
	if(flag == true){
		$("#dental-course").addClass("pitch-on");
	}
};

$(function(){
	(function showdoctorName() {
		var doctorName = $(".doctorName").attr('title');
		if (doctorName && doctorName.length > 4) {
			doctorName = doctorName.substring(0, 3) + "…";
		}
		
		$(".doctorName label").html(doctorName);
	})();
	
	//显示缩略图
	(function showDoctorCaseAvatorPicture(){
		var avatorPicture = $(".header-name img").attr("attr-src");
		if(avatorPicture != null && avatorPicture != ''){
			avatorPicture = $("#domainId").val() + $(".header-name img").attr("attr-src");
		}
		
		var thumbnail = avatorPicture ? getThumbnailURLByWidthAndHeight(avatorPicture, 40, 40):'/static/images/decorator/avatar-user.png';
		$(".header-name img").attr("src", thumbnail);
	})();
	
	//退出登录
	$("#quit").click(function(){
		window.location.href='/logout';  
	});
	
	var url = window.location.href;
	
	if(url.indexOf("teaching-video") > -1){
		$("#video-course").addClass("pitch-on");
	}else if(url.indexOf("doctor-case/list") > -1){
		$("#case-centre").addClass("pitch-on");
	}
	
	$("#video-course").on("click",function(){
		window.location.href='/security/teaching-video';  
	});
	
	$("#dental-course").on("click",function(){
		window.location.href='/security/tooth-tutorial';  
	});
	
	$("#case-centre").on("click",function(){
		window.location.href='/security/doctor-case/list';  
	});
	
	$(".chooseHint,.okHint").on("click",function(){
		$(".overlay").hide();
		$("body").removeClass("body_overall");
	});
	
	$(".blankHint").on("click",function(){
		$(".overlay_blank").hide();
		$("body").removeClass("body_overall");
	});
	
	$("#new_cases").on("click", function(event){
		window.location.href='/security/doctor-case/index';
	}); 
	$("#personal-data").on('click',function(){
		window.location.href='/security/account-info/index';
	})
});

//将秒数转换为时分秒格式
function formatSeconds(value) {
	var theTime = parseInt(value);// 秒
	var theTime1 = 0;// 分
	var theTime2 = 0;// 小时
	
	if(theTime > 60) {
		theTime1 = parseInt(theTime/60);
		theTime = parseInt(theTime%60);
		if(theTime1 > 60) {
			theTime2 = parseInt(theTime1/60);
			theTime1 = parseInt(theTime1%60);
		}
	}
	
	if(theTime < 10){ 
		theTime = '0' + theTime;
	}
	
	if(theTime1 < 10){
		theTime1 = '0' + theTime1;
	}
	
	if(!theTime){ 
		theTime = '00';
	}
	
	var result = "" + theTime;
	
	result = "" + theTime1 + ":" + result; 
	
	if(theTime2 > 0) {
		result = ""+theTime2+":"+result;
	}
	    
	return result;
}

//根据原图url生成指定大小的缩略图url(在图片后缀名前添加类似_50x50的大小信息)
function getThumbnailURL(naturalImgURL, element){
	return getThumbnailURLByWidthAndHeight(naturalImgURL, parseInt(element.width()|(element.css('max-width')?element.css('max-width').replace('px',''):50)),parseInt(element.height()|(element.css('max-height')?element.css('max-height').replace('px',''):50)));
}
 
function getThumbnailURLByWidthAndHeight(naturalImgURL, width, height){
	var url = "";
	if(!naturalImgURL){
		return naturalImgURL;
	}
		                                                                            
	var res = naturalImgURL.split(".");
	
	for(var  i =0; res&&i<res.length; i++){
		url +=res[i];
		if(i == (res.length-2)){
			url+="_";
			url+= width;
			url+="x";
			url+=height;
		}
		
		if(i != (res.length-1)){
			url +=".";
		}
	}
	return url;
}

function showThumbnailImage(element){ 
	var originalImgUrl = element.attr("data-original-img"); 
	element.attr("src", getThumbnailURL(originalImgUrl, element));
}

//根据缩略图url获取原图url
function getNaturalImgURL(thumbnailURL){
	var url = thumbnailURL.replace(/\_[0-9]+[x][0-9]+/,"");
	return url;
}